// ** Global vars ** //
IsMobile = is.mobile();
IsDesktop = is.desktop();
IsSafari = is.safari();




// ** Scroll content function ** //
function scrollContent(){
    var totalScroll = $(document).height() - $(window).height();
    newScroll = $(window).scrollTop();
    currentScroll = newScroll;

    $('.ToAnim').each(function(){
        var object = $(this);
        if(newScroll + $(window).height() * 1 > $(this).offset().top){
            object.addClass('IsAnim');
            object.removeClass('DoneAnim');
        }
        else if(newScroll + $(window).height() < $(this).offset().top) {
            object.removeClass('IsAnim');
            object.addClass('DoneAnim');
        }
    });
}
// ** Accordion function ** //
function slideAccordion(typeSlide,accordion) {
    switch(typeSlide.toLowerCase()) {
        case 'up':
            accordion.removeClass("active");
            accordion.next().slideUp(200);
        break;
        case 'down':
            accordion.addClass("active");
            accordion.next().slideDown(200);
        break;
        case 'toggle':
            accordion.toggleClass("active");
            accordion.next().slideToggle(200);
        break;
    }
}

// ** Toggle utility function ** //
function TheToggler(mainValue, self) {
    var on = 'is-active';

    // get all other toggler with the same value
    $('[data-toggler]').each(function(){
        if ($(this).attr('data-toggler') == mainValue) {
            $(this).toggleClass(on);
        }
    });

    // get all element that needs to be toggle
    $('[data-toggle]').each(function(){

        // check if matching value
        var value = $(this).attr('data-toggle');
        if (mainValue == value) {
            $(this).toggleClass(on);
        }
    });
}

// ** smooth scroll function ** //
function startSmoothScroll() {
    $('a[href*=#]:not([href=#])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
            || location.hostname == this.hostname) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });
}

// ** Sumo select ** //
function initSumoSelect(item) {
    var _item = $(item);
    for (var i = _item.length - 1; i >= 0; i--) {
        var placeholder = $(_item[i]).attr('data-placeholder');

        if (placeholder) {
            $(_item[i]).SumoSelect({
                placeholder: placeholder
            });
        } else {
            $(_item[i]).SumoSelect();
        }
    }
}

// ** Sub nav actions ** //
function subNavActions() {
    $('.TriggerSubNav').click(function() {
        theTrigger = $(this);
        theParent = theTrigger.parent('.has-child');
        theSubNav = theParent.find('ul');

        theParent.toggleClass('is-active');
        theSubNav.slideToggle();
    });
}

// ** Toast function ** //
toastr.options = {
    'closeButton': true,
    'debug': false,
    'newestOnTop': false,
    'progressBar': false,
    'positionClass': 'toast-top-center',
    'preventDuplicates': false,
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
};

    function toast(type, message, timeout, action) {
        timeout = timeout || 1000;
        toastr.options['timeOut'] = timeout;
        toastr.options['onclick'] = function() {};
        if (action) {
            toastr.options['onclick'] = action;
        }
        toastr[type](message);
    }

    function toast_them(toasts) {
        for (var type in toasts) {
            for (var key in toasts[type]) {
                toast(type, toasts[type][key], 5000);
            }
        }
    }

function initIas() {
    $.ias('destroy');

    ias = $.ias({
        container:  '.ias-container',
        item:       '.ias-item',
        pagination: '.pagination',
        next:       '.pagination .next'
    });

    // Adds a link which has to be clicked to load the next page
    //ias.extension(new IASTriggerExtension({

    //    text: TR_PLUS_DE_RESULTAT,
    //    html: '<div class="Spaced_larger-noBottom TextAlign-center"><a href="javascript: return false;" class="Btn show-more">{text}</a></div>'
    //}));

    // Adds a loader image which is displayed during loading
    //ias.extension(new IASSpinnerExtension({
    //    html: '<div class="Spaced_larger-noBottom TextAlign-center"><a href="javascript: return false;" class="Btn is-loading">&nbsp;</a></div>'
    //}));

    ias.extension(new IASPagingExtension());

    ias.extension(new IASHistoryExtension({
        prev: '.dunno'
    }));

    ias.on('rendered', function(items) {
    });

    ias.on('noneLeft', function(items) {
        $('.ias-container .show-more').hide();
    });
}

// ** Load Google font(s) async ** //



// **---------------------------------------------------**
// -------------------------------------------------------
// document ready BEGIN
$(document).ready(function() {

    // *******************************
    // *******************************
    // Functions that get called once on document ready
    // skrollr
    initSumoSelect('select');
    startSmoothScroll();
    subNavActions();
    initIas();
    scrollContent();
    // loadFonts();

    if (IsDesktop) {
        var s = skrollr.init();
    }

    if (IsSafari && $('.BrandOverlay')) {
        $('.BrandOverlay').addClass('is-safari');
    }

    $('[href=#]').on('click', function(e) {
        e.preventDefault();
    });

    $('[data-href]').on('click', function(e) {
        var $this = $(this);
        location.href = $this.data('href');
    });

    // home landing
    // var headerTopHeight = $('.HeaderMain_logo').outerHeight();
    // var headerMiddleHeight = $('.MainNav').outerHeight();
    // var headerBottomHeight = $('.LandingIntro').outerHeight();
    // var headerTotalHeight = headerTopHeight + headerMiddleHeight + headerBottomHeight;
    // console.log(headerTotalHeight);

    //	-------------------------------------------------
    // FORM Utilities
    // when upload a file, get it's name and display it in the label
    $('input[type="file"]').change(function (e) {
        var fileName = e.target.files[0].name;

        $(this).parent().find('label').text(fileName);
    });

    // when submiting a form, disable the button and show "sending..." in in
    $('form').submit(function (e) {
        var submit_button = $(this).find('button[type="submit"]');

        submit_button.data('text', submit_button.text());
        var span_loading_msg = submit_button.parent().find('span');

        if (span_loading_msg.length > 0) {
            submit_button.addClass('disabled').text(span_loading_msg.text());
        } else {
            $(submit_button).addClass('disabled');
        }
    });

    //  -------------------------------------------------
    //  ACCORDIONs - Slide down - up

    var accordion = $(".Accordions");
    var accordion_length = accordion.length;

    if (accordion_length > 0) {
        var block = $(".Accordions_block");
        var header = $(".Accordions_header");
        var title = $(".Accordions_title");
        var content = $(".Accordions_content");

        header.click(function () {

            var self = $(this);

            if (!accordion.hasClass('Accordions-collapse')) {
                var accordions_active = $('.Accordions_block .Accordions_header.active');

                accordions_active.removeClass('active');

                if (accordions_active.length > 0) {
                    accordions_active.next().slideUp(function () {
                        if (self[0] != $(this).prev()[0]) {
                            slideAccordion('toggle', self);
                        }
                    });
                }
                else {
                    slideAccordion('toggle', self);
                }
            }
            else {
                slideAccordion('toggle', self);
            }
        });
    };

    // *******************************
    // *******************************
    // Toggle utility

    // get the data-toggler and its value
    $('[data-toggler]').click(function(e) {
        var self = $(this);
        var mainValue = $(this).attr('data-toggler');

        // then execute the function to toggleClass to all its matching toggler
        TheToggler(mainValue, self);
        e.preventDefault();
    });
});
// document ready END
// -------------------------------------------------------
// **----------------------------------------------------**


// **---------------------------------------------------**
// -------------------------------------------------------
// on load BEGIN
$(window).on('load', function(){
    // ***
    // du code soit pour le loading mask ou
    // pour initialiser le loading du site
    // **

}).scroll(function(){
    // call the function once the dom is loaded and
    // the user has scrolled
    scrollContent();
});
// on load END
// -------------------------------------------------------
// **---------------------------------------------------**

function reenableButtons($form) {
    $form = $form || $(document);
    var $button = $form.find('button[type=submit]');
    $button.removeClass('disabled');
    if ($button.data('text')) {
        $button.text($button.data('text'));
    }
}

function sendFormAjax($form, method, callback, callbackArgs) {
    const key = $form[0].querySelector('.recaptcha-key');
    grecaptcha.ready(function() {
        grecaptcha.execute(key.value, {action: 'submit'}).then(function (token) {
            var formData = null;
            var processData = true;
            var contentType = null;
            method = method ? method : 'POST';
            if ($form.find('input[type=file]').length) {
                if (typeof IE9 !== 'undefined' && IE9) {
                    // Envoyer des fichiers dans IE9 et moins ça marche pas en ajax
                    if (!$form.parents('body').length) {
                        $(document.body).append($form);
                    }
                    $form.unbind('submit');
                    $form.unbind();
                    $form[0].onSubmit = false;
                    $form.submit();
                    return false;
                } else {
                    processData = false;
                    contentType = false;
                    formData = new FormData($form[0]);
                    if(token){
                        formData.append('captcha-token',token);
                        formData.append('captcha-action','submit');
                    }
                }
            } else {
                formData = new FormData($form[0]);
                if(token){
                    formData.append('captcha-token',token);
                    formData.append('captcha-action','submit');
                }
                // Si on n'a pas de fichier à envoyer, on va juste faire un serialize bin normal
                //formData = formData.serialize();
                formData = new URLSearchParams(formData).toString();
                contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
            }
            var action = $form.attr('action');
            if (action.indexOf('?') < 0) {
                action += '?';
            }
            action += '&ajax';
            postAjax($form, formData, action, method, processData, contentType,callback, callbackArgs)
        })
    });
}


function postAjax($form, formData,action,method,processData,contentType,callback, callbackArgs){
    $.ajax({
        url: action,
        type: method,
        data: formData,
        dataType: 'json',
        processData: processData,
        contentType: contentType,
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
        },
        success: function (response) {
            if (callback) {
                if (window[callback]) {
                    callback = window[callback];
                }
                var args = [response];
                args = args.concat(callbackArgs);
                callback.apply(null, args);
            } else {
                if (response.result) {
                    if (response.replaceHtml) {
                        $form.replaceWith($(response.replaceHtml));
                    }
                }
            }
        }
    });
}

function resetFormErrors($form) {
    $form.find('.Alert.has-error').empty();
    $form.find('.Alert').addClass('hide');
    $form.find('.js-error').remove();
    $form.find('.FormElement.has-error').removeClass('has-error')
}
function displaySuccess($form) {
    if (typeof $form == 'string') { $form = $($form); }
    resetFormErrors($form);
    var $success = $form.parent().find('.Alert.has-success');
    $success.removeClass('hide');
    if ($success.length) {
        $form.hide();
        $('html, body').animate({
            scrollTop: $form.parent().offset().top
        }, 600);
    }
}
function displayErrors($form, messages) {
    if (typeof $form == 'string' && $form) { $form = $($form); }
    $form = $form || $(document);
    resetFormErrors($form);
    var focusIt = true;
    for (var name in messages) {
        if (name == 'global') {
            var $wrapperError = $form.find('.Alert.has-error');
            if ($wrapperError.length) {
                $wrapperError.removeClass('hide').append(messages[name]);
            } else {
                toast('error', messages[name], 5000);
            }
            $('html, body').animate({
                scrollTop: $form.parent().offset().top
            }, 600);
        } else {
            var $ele = $form.find('*[name=' + name + ']');
            if ($ele.length) {
                if (focusIt && ($ele[0].offsetParent !== null)) {
                    $ele.focus();
                    focusIt = false;
                }
                var $formElement = $ele.closest('.FormElement');
                if ($formElement.length) {
                    $formElement.addClass('has-error');
                    $formElement.after('<label for="'+ $ele.attr('id') + '" class="js-error" style="display:block !important;">' + messages[name] + '</label>')
                }
            }
        }
    }
}
function initJsForm($wrapper) {
    $wrapper = $wrapper || $(document);
    $wrapper.find('.js-form').on('submit', function(e){
        var $form = $(this);
        resetFormErrors($form);

        $form.find('input[name=special_email]').val('');
        sendFormAjax($form, 'POST', function (response) {
            if (response.toast) {
                toast_them(response.toast);
            }
            if (!response.error) {
                if (response.url) {
                    location.href = response.url;
                } else if ($form.data('reload') || response.reload) {
                    location.href = location.href;
                }
                displaySuccess($form);
            } else {
                reenableButtons($form);
                if (response.message) {
                    displayErrors($form, { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($form, response.messages);
                }
            }
        });
        e.preventDefault();
    });
}
$(function() {
    initJsForm();
});
function loadSearchResults(url, data) {
    if (url.indexOf('?') < 0) {
        url += '?';
    }
    url += '&ajax';
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
        },
        success: function (response) {
            if (!response.error) {
                $('#search_results').html(response.html);
                $('#search_pagination').html(response.pagination);

                reenableButtons($('#search-form'));
                initIas();
                $('html,body').animate({
                    scrollTop: $('#search_results').offset().top
                }, 500);
            } else {
                if (response.message) {
                    displayErrors($('#search-form'), { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($('#search-form'), response.messages);
                }
            }
        }
    });
}
$(function() {
    $('#search-form').on('submit', function (e) {
        var $this = $(this);
        resetFormErrors($this);
        loadSearchResults($this.attr('action'), $this.serialize());
        e.preventDefault();
    });
    $('#search_term').focus();
});
function initCalendrier() {
    $('#calendrier').find('.prev, .next').on('click touchstart', function(e) {
        var $this = $(this);
        var url = $this.attr('href');
        if (url.indexOf('?') < 0) {
            url += '?';
        }
        url += '&ajax';
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            beforeSend: function () {
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('Une erreur est survenue, veuillez réessayer plus tard.');
            },
            success: function (response) {
                if (!response.error) {
                    $('#calendrier').replaceWith($(response.html));
                    initCalendrier();
                } else {
                    if (response.message) {
                        displayErrors(null, { 'global': response.message });
                    }
                }
            }
        });
        e.preventDefault();
    });
}
$(function() {
    initCalendrier();
});
function readCart() {
    var shoppingcart = $.cookie(COOKIE_CART);
    if (shoppingcart) {
        shoppingcart = JSON.parse(shoppingcart);
    }
    if (!(shoppingcart instanceof Object) || $.isArray(shoppingcart)) {
        shoppingcart = {};
    }
    return shoppingcart;
}
function writeCart(shoppingcart) {
    $.cookie(
        COOKIE_CART, JSON.stringify(shoppingcart), {
            expires: 30,
            path: '/'
        }
    );
    if (MEMBER_CONNECTED) {
        $.ajax({
            url: '/json?module=cart_item&method=sync_cart'
        });
    }
    if (Object.keys(shoppingcart).length == 0) {
        $('.TheCart_navigation').hide();
    } else {
        $('.TheCart_navigation').show();
    }
    var qty = 0;
    for (var key in shoppingcart) {
        qty += parseInt(shoppingcart[key]['qty']);
    }
    $('#cart_qty').text(parseInt(qty));
}
function modifyCart(id, qty, gift, web, abo, adding) {
    qty = qty || 1;
    qty = parseInt(qty);
    gift = gift || false;
    web = web || false;
    abo = abo || false;
    adding = adding || false;
    var type = SHOPPING_CART_TYPE_DEFAULT;
    var type2 = SHOPPING_CART_TYPE_PAPER;
    if (gift) { type = SHOPPING_CART_TYPE_GIFT; }
    if (web) { type2 = SHOPPING_CART_TYPE_WEB; }
    if (abo) { qty = 1; }
    var shoppingcart = readCart();
    var key = type + '-' + type2 + '-' + id;
    shoppingcart[key] = {
            'id': id,
            'qty': !abo && adding && key in shoppingcart ? shoppingcart[key]['qty'] + qty : qty,
            'type': type,
            'web': web
        };
    writeCart(shoppingcart);
    renderCart();
}
function deleteFromCart(id, gift, web) {
    gift = gift || false;
    var type = SHOPPING_CART_TYPE_DEFAULT;
    var type2 = SHOPPING_CART_TYPE_PAPER;
    if (gift) { type = SHOPPING_CART_TYPE_GIFT; }
    if (web) { type2 = SHOPPING_CART_TYPE_WEB; }
    var shoppingcart = readCart();
    var key = type + '-' + type2 + '-' + id;
    if (key in shoppingcart) {
        delete shoppingcart[key];
    }
    writeCart(shoppingcart);
    renderCart();
}
function renderCart() {
    if ($('#shopping-cart').length) {
        $.ajax({
            url: '/json?module=cart_item&method=cart&ajax',
            type: 'GET',
            dataType: 'json',
            beforeSend: function () {
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('Une erreur est survenue, veuillez réessayer plus tard.');
            },
            success: function (response) {
                if (response.toast) {
                    toast_them(response.toast);
                }
                if (!response.error) {
                    $('#shopping-cart').replaceWith($(response.html));
                    initCart();
                    initJsForm($('#shopping-cart'));
                    initPlusMinus($('.cart-change-qty'));
                } else {
                    if (response.message) {
                        displayErrors(null, {'global': response.message});
                    }
                }
            }
        });
    }
}
function adjustPlusMinusStatus($wrapper) {
    $wrapper = $wrapper || $(document);
    $wrapper.find('.inputNumber input').each(function(e) {
        var $this = $(this);
        var $parent = $this.closest('.inputNumber');
        var $plus = $parent.find('.control-add');
        var $minus = $parent.find('.control-minus');
        if ($this.val() <= 1) {
            $minus.addClass('disabled');
        } else {
            $minus.removeClass('disabled');
        }
        var qty = 0;
        var shoppingcart = readCart();
        for (var key in shoppingcart) {
            if (shoppingcart[key]['id'] == $this.data('id')) {
                qty += shoppingcart[key]['qty'];
            }
        }
        if ($this.data('max') && qty >= $this.data('max')) {
            $plus.addClass('disabled');
        } else {
            $plus.removeClass('disabled');
        }
    });
}
function initPlusMinus($wrapper) {
    $wrapper = $wrapper || $(document);
    $wrapper.find('.control-minus, .control-add').on('click', function(e) {
        var $this = $(this);
        if ($this.hasClass('disabled')) { return; }
        var $parent = $this.closest('.inputNumber');
        var $ele = $parent.find('input');
        if ($ele.length) {
            var new_val = parseInt($ele.val()) + ($this.hasClass('control-minus') ? -1 : 1);
            if (new_val > 0) {
                $ele.val(new_val);
                if ($ele.closest('.cart-change-qty').length) {
                    modifyCart($ele.data('id'), $ele.val(), $ele.data('gift'), $ele.data('web'), $ele.data('abo'), false);
                }
            }
        }
        adjustPlusMinusStatus($($parent.parent()));
        e.preventDefault();
    });
    adjustPlusMinusStatus($wrapper);
}
function writePromos(promos) {
    $.cookie(
        COOKIE_PROMOS, JSON.stringify(promos), {
            expires: 30,
            path: '/'
        }
    );
    if (MEMBER_CONNECTED) {
        $.ajax({
            url: '/json?module=cart_promo&method=sync_promos'
        });
    }
}
function readPromos() {
    var promos = $.cookie(COOKIE_PROMOS);
    if (promos) {
        promos = JSON.parse(promos);
    }
    if (promos instanceof Object && !$.isArray(promos)) {
        promos = Object.keys(promos).map(function(k) { return promos[k] });
    }
    return promos;
}
function deletePromo(code) {
    var promos = readPromos();
    var index = promos.indexOf(code);
    if (index > -1) {
        delete promos[index];
        writePromos(promos);
        renderCart();
    }
}
function initCart() {
    $('.cart-quantity').on('change', function (e) {
        var $this = $(this);
        $this.val($this.val().replace(/[^\d]/, ''));
        modifyCart($this.data('id'), $this.val(), $this.data('gift'), $this.data('web'), $this.data('abo'), false);
        e.preventDefault();
    });
    $('.remove-from-cart').on('click touchstart', function (e) {
        var $this = $(this);
        deleteFromCart($this.data('id'), $this.data('gift'), $this.data('web'));
        e.preventDefault();
    });
    $('.remove-promo').on('click touchstart', function(e) {
        var $this = $(this);
        deletePromo($this.data('code'));
        renderCart();
        e.preventDefault();
    });
    $('.cart-gift').on('change', function(e) {
        var $this = $(this);
        var old_type = SHOPPING_CART_TYPE_GIFT;
        if (this.checked) {
            old_type = SHOPPING_CART_TYPE_DEFAULT;
        }
        var type2 = $this.data('web') ? SHOPPING_CART_TYPE_WEB : SHOPPING_CART_TYPE_PAPER;
        var shoppingcart = readCart();
        var new_shoppingcart = {};
        for (var key in shoppingcart) {
            if (key == old_type + '-' + type2 + '-' + $this.data('id')) {
                var new_type = SHOPPING_CART_TYPE_GIFT;
                if (old_type == SHOPPING_CART_TYPE_GIFT) {
                    new_type = SHOPPING_CART_TYPE_DEFAULT;
                }
                var new_key = new_type + '-' + type2 + '-' + $this.data('id');
                if (new_key in shoppingcart) {
                    shoppingcart[new_key]['qty'] += shoppingcart[key]['qty']
                } else {
                    new_shoppingcart[new_key] = shoppingcart[key];
                    new_shoppingcart[new_key]['type'] = new_type;
                }
            } else {
                new_shoppingcart[key] = shoppingcart[key];
            }
        }
        writeCart(new_shoppingcart);
        renderCart();
    });
    $('#form-promo').on('submit', function(e) {
        e.preventDefault();

        var $form = $(this);
        sendFormAjax($form, 'POST', function (response) {
            if (response.toast) {
                toast_them(response.toast);
            }
            if (!response.error) {
                $('#shopping-cart').replaceWith($(response.html));
                initCart();
                initJsForm($('#shopping-cart'));
                initPlusMinus($('.cart-change-qty'));
            } else {
                reenableButtons($form);
                if (response.message) {
                    displayErrors($form, { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($form, response.messages);
                }
            }
        });
    })
}
$(function() {
    initPlusMinus();
    $('.add-to-cart').on('click touchstart', function (e) {
        var $this = $(this);
        var quantity = $this.data('qty');
        var $parent = $this.closest('.cart-add-qty');
        if ($parent.length) {
            var $ele = $parent.find('input');
            if ($ele.length) {
                quantity = $ele.val();
            }
        }
        modifyCart($this.data('id'), quantity, $this.data('gift'), $this.data('web'), $this.data('abo'), true);
        toast('success', TR_AJOUTER_PANIER.replace('%qty%', quantity || 1), null, URL_PANIER ? function() { location.href = URL_PANIER } : null);
        e.preventDefault();
    });
    initCart();
});
function toggleBillingSame() {
    var ele = document.getElementById('billing_same');
    var $details = $('#billing_details');
    if (ele) {
        if (ele.checked) {
            $details.hide();
        } else {
            $details.show();
        }
    }
}
function toggleCreateAccount() {
    if ($('#create-account-input').val() == 1) {
        $('#personnal_informations').show();
        $('#shipping_informations').show();
        $('#gift_informations').show();
        $('.BtnWrapper button[type=submit]').removeClass('disabled');
    }
}
$(function() {
    $('#create-account').on('click', function(e) {
        $('#create-account-input').val('1');
        toggleCreateAccount();
        e.preventDefault();
    });

    $('#billing_same').on('change', function (e) {
        toggleBillingSame();
    });
    toggleCreateAccount();
    toggleBillingSame();

    $('#buy-confirm').on('submit', function(e) {
        var $form = $(this);
        e.preventDefault();

        sendFormAjax($form, 'POST', function (response) {
            if (!response.error) {
                if (response.url) {
                    location.href = response.url;
                } else if ($form.data('reload')) {
                    location.href = location.href;
                } else {
                    $form.unbind('submit');
                    $form.unbind();
                    $form.attr('action', $form.data('action'));
                    $form.submit();
                }
            } else {
                reenableButtons($form);
                if (response.message) {
                    displayErrors($form, { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($form, response.messages);
                }
            }
        });
    });
});

function toggleProfileForm($form, enable) {
    if (enable) {
        $form.find('.FormElement').removeClass('is-unstyled');
        $form.find('.cancel-button').show();
        $form.find('.save-button').show();
        $form.find('.new-password').show();
        $form.find('.modify-toggle').hide();
    } else {
        resetFormErrors($form);
        $form.find('.FormElement').addClass('is-unstyled');
        $form.find('.cancel-button').hide();
        $form.find('.save-button').hide();
        $form.find('.new-password').hide();
        $form.find('#current_password').val('');
        $form.find('#password_confirm').val('');
        $form.find('#password').val('');
        $form.find('.modify-toggle').show();
    }
}

function initProfileButtons($wrapper) {
    $wrapper = $wrapper || $(document);
    $wrapper.find('.modify-toggle').on('click', function(e) {
        var $this = $(this);
        var $form = $this.closest('form');
        toggleProfileForm($form, true);
        e.preventDefault();
    });
    $wrapper.find('.cancel-button').on('click', function(e) {
        var $this = $(this);
        var $form = $this.closest('form');
        toggleProfileForm($form, false);
        e.preventDefault();
    });
    $wrapper.find('.save-toggle').on('click', function(e) {
        var $this = $(this);
        var $form = $this.closest('form');
        $form.submit();
        e.preventDefault();
    });
    $wrapper.find('form').on('submit', function(e) {
        var $form = $(this);
        e.preventDefault();
        resetFormErrors($form);
        sendFormAjax($form, $form.attr('method'), function(response) {
            if (!response.error) {
                toggleProfileForm($form, false);
                toast('success', response.message);
            } else {
                reenableButtons($form);
                if (response.message) {
                    displayErrors($form, { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($form, response.messages);
                }
            }
        });
    });
}

$(function() {
    initProfileButtons($('.MyProfile'));

    $('.LoginModal_forgot').on('click', function(e) {
        $('.LoginModal_resetpassword').hide();
        $('.LoginModal_login').hide();
        $('.LoginModal_lostpassword').show();
        e.preventDefault();
    });

    $('.LoginModal_lostpassword .cancel-button').on('click', function(e) {
        $('.LoginModal_login').show();
        $('.LoginModal_lostpassword').hide();
        $('.LoginModal_resetpassword').hide();
        e.preventDefault();
    });

    $('.LoginModal_resetpassword .cancel-button').on('click', function(e) {
        $('.LoginModal_login').show();
        $('.LoginModal_lostpassword').hide();
        $('.LoginModal_resetpassword').hide();
        e.preventDefault();
    });

    $('.LoginModal_lostpassword form').on('submit', function(e) {
        var $form = $(this);
        resetFormErrors($form);
        if ($form.valid()) {
            sendFormAjax($form, 'POST', function (response) {
                if (!response.error) {
                    toast('success', response.message);
                    $('.LoginModal_login').show();
                    $('.LoginModal_lostpassword').hide();
                } else {
                    reenableButtons($form);
                    if (response.message) {
                        displayErrors($form, { 'global': response.message });
                    } else if (response.messages) {
                        displayErrors($form, response.messages);
                    }
                }
            });
        }
        e.preventDefault();
    });
});

function closePopup($popup) {
    $('.Popup_overlay').remove();
    $popup.hide();
}
function showPopup($popup) {
    var popups_shown = $.cookie('popups_shown');
    if (popups_shown) {
        popups_shown = JSON.parse(popups_shown);
    }
    if (popups_shown instanceof Object && !$.isArray(popups_shown)) {
        popups_shown = Object.keys(popups_shown).map(function(k) { return popups_shown[k] });
    } else if (typeof popups_shown == 'undefined') {
        popups_shown = [];
    }
    if ($popup.data('once') && popups_shown.indexOf($popup.data('id')) > -1) {
        $popup.data('shown', 1);
    }
    if ($popup.data('shown')) {
        return;
    }
    $('.Popup_overlay').remove();
    var $overlay = $('<div>');
    $overlay.addClass('Popup_overlay');
    $overlay.css({
        'z-index': 9999,
        'position': 'fixed',
        'background-color': '#000000',
        'opacity': '0.5',
        'width': '100%',
        'height': '100%'
    });
    $overlay.on('click', function(e) {
        closePopup($popup);
        e.stopPropagation();
        e.preventDefault();
    });
    $popup.find('a.Popup_close').on('click', function(e) {
        closePopup($popup);
        e.stopPropagation();
        e.preventDefault();
    });
    $('body').prepend($overlay);
    $popup.css({
        'z-index': 10000,
        'position': 'fixed'
    });
    $popup.show();
    $popup.data('shown', 1);
    if ($popup.data('once')) {
        popups_shown.push($popup.data('id'));
        $.cookie(
            'popups_shown', JSON.stringify(popups_shown), {
                path: '/'
            }
        );
    }
}
var scrollTop = 0;
$(function() {
    $('.Popup').each(function(e) {
        var $popup = $(this);
        if ($popup.hasClass('scrollup')) {
            $(window).on('scroll', function(e) {
                var currentScroll = $(this).scrollTop();
                console.log(currentScroll + ' vs ' + scrollTop);
                if (currentScroll < scrollTop - 10) {
                    setTimeout(function() { showPopup($popup); }, $popup.data('seconds') ? parseInt($popup.data('seconds')) * 1000 : 0);
                }
                scrollTop = currentScroll;
            });
        } else {
            setTimeout(function() { showPopup($popup); }, $popup.data('seconds') ? parseInt($popup.data('seconds')) * 1000 : 0);
        }
    });
});
